'use client';

import useCarouselAnimation from '@src/hooks/useCarouselAnimation';
import { RootStateTypes, useTypedAppSelector } from '@src/redux/store/store.types';
import useCarouselIndicatorAnimation from '@src/hooks/useCarouselIndicatorAnimation';
import { TailwindBreakPoints } from '@src/typescriptGlobals/types';

import Container from '@src/components/Shared/Container';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import CarouselContainer from '@src/components/Shared/CarouselContainer';
import NextImage from '@src/components/Shared/NextImage';
import ParagraphSeparator from '@src/components/Shared/ParagraphSeparator';
import CarouselIndicators from '@src/components/Shared/CarouselIndicators';

import { ServiceOverviewPropTypes, ServiceTypes } from './ServiceOverview.types';

const ServiceOverview: React.FC<ServiceOverviewPropTypes> = ({
  id,
  services,
  description,
  title,
}) => {
  const {
    carouselRef,
    containerRef,
    handleAddCarouselItemsToRef,
    activeIndex,
    handleJumpToSlide,
    maxIndex,
  } = useCarouselAnimation({});
  const { width } = useTypedAppSelector((state: RootStateTypes) => state.windowRXS);

  const { handleAddIndicatorRef } = useCarouselIndicatorAnimation({});

  const onNavigate = (slug: string) => {
    return () => {
      window.open(slug, '_blank');
    };
  };

  return (
    <Container id={id} containerRef={containerRef}>
      <div className='mb-[4.5rem]'>
        {title && (
          <div className='mx-auto mb-[2.5rem] max-w-[103.7rem] text-center text-[1.8rem] font-[500]'>
            {title}
          </div>
        )}
        {description && (
          <div className='mx-auto max-w-[103.7rem] text-center text-[1.6rem]'>
            <RenderBodyCopy bodyCopy={description?.json?.content} />
          </div>
        )}
      </div>

      <CarouselContainer
        carouselRef={width >= TailwindBreakPoints.lg ? null : carouselRef}
        className='lg:flex lg:justify-center [&>*:not(:last-child)]:mr-[1.6rem] md:[&>*:not(:last-child)]:mr-[2.4rem] 2xl:[&>*:not(:last-child)]:mr-[3.6rem]'
      >
        {services?.map((service: ServiceTypes) => {
          const linkElement = service?.link ? (
            <div
              ref={width >= TailwindBreakPoints.lg ? null : handleAddCarouselItemsToRef}
              className='group-[.post-col]:md:w-30%] flex w-[31.4rem] flex-none flex-shrink-0 cursor-pointer flex-col overflow-hidden pb-[2rem]'
              onClick={onNavigate(service.link)}
            >
              <NextImage
                src={service?.mediaPrimary?.url}
                alt={service?.mediaPrimary?.description ?? 'VALD Service Image'}
                className='mb-[1.2rem] w-full rounded-[0.8rem]'
              />

              <div className='mx-auto mt-[2rem] flex flex-grow flex-col'>
                <p className='mb-[1.8rem] text-[2.2rem] font-normal leading-[1.3] text-body-copy--dark'>
                  {service?.title && (
                    <p className='mb-[1.8rem] text-[2.2rem] font-normal leading-[1.3] text-body-copy--dark'>
                      {service?.title}
                    </p>
                  )}
                </p>
                <ParagraphSeparator className='flex flex-grow flex-col [&>div:has(a):last-child]:mt-auto'>
                  <RenderBodyCopy
                    bodyCopy={service?.bodyCopy?.json?.content}
                    className='font-[200] text-body-copy--dark'
                  />
                </ParagraphSeparator>
              </div>
            </div>
          ) : (
            // If link is empty, just render the content without the Link wrapper
            <div
              ref={width >= TailwindBreakPoints.lg ? null : handleAddCarouselItemsToRef}
              className='flex w-[31.4rem] flex-none flex-shrink-0 flex-col overflow-hidden pb-[2rem] group-[.post-col]:md:w-[30%] '
            >
              <NextImage
                src={service?.mediaPrimary?.url}
                alt={service?.mediaPrimary?.description ?? 'VALD Service Image'}
                className='mb-[1.2rem] w-full rounded-[0.8rem]'
              />

              <div className='mx-auto mt-[2rem] flex flex-grow flex-col'>
                {service?.title && (
                  <p className='mb-[1.8rem] text-[2.2rem] font-normal leading-[1.3] text-body-copy--dark'>
                    {service?.title}
                  </p>
                )}
                <ParagraphSeparator className='flex flex-grow flex-col [&>div:has(a):last-child]:mt-auto'>
                  <RenderBodyCopy
                    className='font-[200] text-body-copy--dark'
                    bodyCopy={service?.bodyCopy?.json?.content}
                  />
                </ParagraphSeparator>
              </div>
            </div>
          );

          return linkElement;
        })}
      </CarouselContainer>

      <CarouselIndicators
        className='flex justify-center'
        maxIndex={maxIndex}
        handleJumpToSlide={handleJumpToSlide}
        handleAddIndicatorRef={handleAddIndicatorRef}
        activeIndex={activeIndex}
      />
    </Container>
  );
};

export default ServiceOverview;
